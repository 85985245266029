import { render, staticRenderFns } from "./BannerPage.vue?vue&type=template&id=15e35413"
import script from "./BannerPage.vue?vue&type=script&lang=js"
export * from "./BannerPage.vue?vue&type=script&lang=js"
import style0 from "./BannerPage.vue?vue&type=style&index=0&id=15e35413&prod&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.12_babel-core@7.0.0-bridge.0_@babel+core@7.25.8__cac_7uvw3tio4x4egbur7wz2ifovwa/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports